import { render, staticRenderFns } from "./Aladin.vue?vue&type=template&id=b3908ea0&scoped=true&"
import script from "./Aladin.vue?vue&type=script&lang=js&"
export * from "./Aladin.vue?vue&type=script&lang=js&"
import style0 from "./Aladin.vue?vue&type=style&index=0&id=b3908ea0&media=screen&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3908ea0",
  null
  
)

export default component.exports